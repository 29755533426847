import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {  QuotationIcon } from '../../globalStyle';
import { capitalizeFirstLetter } from '../../helpers/globalHelper';
import { Skeleton } from '@mui/material';
import {
  HeaderBox,
  IconBody,
  IconsContainer,
  NavbarContainer,
  NavbarSection,
  Navigation
} from './navbar.style';
import useModal from '../../hooks/useModel';
import QuotationDialog from '../quotation-dialog/quotation-dialog.component';


const Navbar = () => {
  const { categories, status } = useSelector((state) => state.categories);
  const location = useLocation(); // Hook to get the current location
  const { open, handleOpen, handleClose, } = useModal();
  // Determine the number of skeletons to display
  const skeletonCount = Math.max(5, categories.length); // Adjust based on typical category count

  // Function to render skeleton loaders
  const renderSkeletons = (count) => {
    return Array.from(new Array(count)).map((_, index) => (
      <li key={index}>
        <Skeleton animation="wave" width={200} height={50} />
      </li>
    ));
  };

  return (
    <NavbarSection>
      <NavbarContainer>
        <HeaderBox>
          <Navigation>
            <ul>
              <li>
                <RouterLink to="/" className={location.pathname === '/' ? 'active' : ''}>
                  Home
                </RouterLink>
              </li>
              {status === 'loading' ? (
                renderSkeletons(skeletonCount)
              ) : status === 'failed' ? (
                <li>Error loading categories</li>
              ) : status === 'succeeded' && categories.length > 0 ? (
                categories.map((category) => (
                  <li key={category.id}>
                    <RouterLink
                      to={`/shop/${category.category_slug}/${category.id}`}
                      className={location.pathname === `/shop/${category.id}` ? 'active' : ''}
                    >
                      {capitalizeFirstLetter(category.name)}
                    </RouterLink>
                  </li>
                ))
              ) : (
                ''
              )}
            </ul>
          </Navigation>
          <IconBody>
            <IconsContainer  onClick={handleOpen}>
              <QuotationIcon />
              <span>Request for Quotation</span>
            </IconsContainer>
          </IconBody>
        </HeaderBox>
      </NavbarContainer>

      <QuotationDialog
        open={open}
        handleClose={handleClose}
      />
    </NavbarSection>
  );
};

export default Navbar;
